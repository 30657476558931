import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import StudentAssessmentResultsDrawer from "../StudentAssessmentResultsDrawer";
import { timeToDateWithTime } from "../../utils";
import CheckCircleOutline from "assets/icons/check-circle-outline.svg";
import ChevronRightMini from "assets/icons/chevron-right-mini.svg";
import XCircleOutline from "assets/icons/x-circle-outline.svg";

const StudentAssessmentItem = (props) => {
  const { completed, correctCount, failed, isScreener, passed, submittedAt, title, totalCount } =
    props.meta;

  return (
    <Drawer>
      <DrawerTrigger>
        <button
          className="py-4 px-4 sm:px-5 flex items-center justify-between w-full group hover:bg-gray-50 group-last:rounded-b-lg"
          data-testid={`student-assessment-results-item-${props.id}`}
          type="button"
        >
          <div className="flex items-center space-x-2">
            {passed || completed ? (
              <CheckCircleOutline className="w-6 h-6 text-green-500 fill-green-100" />
            ) : (
              <XCircleOutline className="w-6 h-6 text-red-500 fill-red-100" />
            )}
            <h5 className="text-gray-600 font-semibold group-hover:underline">{title}</h5>
            <span className="text-xs text-gray-500">
              {submittedAt ? timeToDateWithTime(submittedAt) : ""}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <span
              className={twMerge(
                cx("text-[10px] rounded-full px-4 py-0.5 font-semibold uppercase", {
                  "bg-red-100 text-red-800": failed,
                  "bg-green-100 text-green-800": passed || completed,
                })
              )}
            >
              {isScreener ? "Completed" : passed ? "Passed" : completed ? "Completed" : "Failed"} -{" "}
              {correctCount}/{totalCount}
            </span>
            <ChevronRightMini className="text-gray-600 w-5 h-5" />
          </div>
        </button>
      </DrawerTrigger>
      <DrawerContent data-testid={`student-assessment-results-drawer-${props.id}`}>
        <StudentAssessmentResultsDrawer {...props} />
      </DrawerContent>
    </Drawer>
  );
};

export default StudentAssessmentItem;
