import React from "react";
import Header from "./Header";
import ExpandedContent from "./ExpandedContent";
import Stats from "./Stats";
import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
} from "../StudentAssessment";
import PropTypes from "prop-types";

export default function StudentSharedReadingAssessment({ assessmentId, open = false, paths }) {
  return (
    <StudentAssessment
      assessmentId={assessmentId}
      paths={paths}
      open={open}
      data-testid="shared-reading-lesson-card"
      queryKey={["student-shared-reading-assessment", assessmentId]}
    >
      <Header />
      <StudentAssessmentContentExpanded>
        <Stats />
        <ExpandedContent />
      </StudentAssessmentContentExpanded>
      <StudentAssessmentContentCollapsed>
        <Stats />
      </StudentAssessmentContentCollapsed>
    </StudentAssessment>
  );
}

StudentSharedReadingAssessment.propTypes = {
  assessmentId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  paths: PropTypes.object.isRequired,
};
