import React from "react";

import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentHeader,
} from "../StudentAssessment";
import BookOpenSolid from "assets/icons/book-open-solid.svg";
import CompleteAssessmentForm from "./CompleteAssessmentForm";

const typeToTitleMap = {
  StudentLetterNamesAndSoundsCard: "Letter Names and Sounds",
  StudentSoundAndBlendCard: "Sound and Blend",
  StudentDecodingByAnalogyCard: "Decoding by Analogy",
  StudentWordDecodingCard: "Word Decoding",
  StudentTrackingDecodableTextCard: "Tracking Decodable Text",
  StudentSpellingCard: "Spelling",
};
const typeToIdMap = {
  StudentLetterNamesAndSoundsCard: "student-letter-names-and-sounds-card",
  StudentSoundAndBlendCard: "student-sound-and-blend-card",
  StudentDecodingByAnalogyCard: "student-decoding-by-analogy-card",
  StudentWordDecodingCard: "student-word-decoding-card",
  StudentTrackingDecodableTextCard: "student-tracking-decodable-text-card",
  StudentSpellingCard: "student-spelling-card",
};
const typeToTooltipContentMap = {
  StudentLetterNamesAndSoundsCard: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Letter Names and Sounds Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      This assessment measures a student&apos;s ability to recognize and name letters and their
      corresponding sounds.
    </p>,
  ],
  StudentSoundAndBlendCard: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Sound and Blend Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      This assessment evaluates a student&apos;s ability to blend sounds to form words.
    </p>,
  ],
  StudentDecodingByAnalogyCard: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Decoding by Analogy Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      This assessment measures a student&apos;s ability to decode new words by using known words as
      analogies.
    </p>,
  ],
  StudentWordDecodingCard: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Word Decoding Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      This assessment evaluates a student&apos;s ability to decode individual words.
    </p>,
  ],
  StudentTrackingDecodableTextCard: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Tracking Decodable Text Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      This assessment measures a student&apos;s ability to track and read decodable text accurately.
    </p>,
  ],
  StudentSpellingCard: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Spelling Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      This assessment evaluates a student&apos;s spelling skills.
    </p>,
  ],
};

const typeToDescriptionMap = {
  StudentLetterNamesAndSoundsCard: [
    <p className="mb-6" key="paragraph-1">
      <strong> 1. Introduce letter names </strong> <br />
      “Let’s work with letters, we’ll learn # new letters today! This is capital X and lowercase X
      <br />
      What letter?” <br />
      <strong> 2. Letter name practice </strong> <br />
      “I’ll name a letter. You point to it and say it back to me” “Now I’ll point to a letter and
      you say the name to me” <br />
      <strong> 3. Introduce letter sounds </strong> <br />
      “Each letter has a sound. The letter X says /X/. What sound?” <br />
      <strong> 4. Letter sound practice </strong> <br />
      “Now I’ll point to a letter and you say the sound” <br />
    </p>,
  ],
  StudentSoundAndBlendCard: [
    <p className="mb-6" key="paragraph-1">
      <strong> 1. Introduce letter names </strong> <br />
      “Let’s work with letters, we’ll learn # new letters today! This is capital X and lowercase X
      <br />
      What letter?” <br />
      <strong> 2. Letter name practice </strong> <br />
      “I’ll name a letter. You point to it and say it back to me” “Now I’ll point to a letter and
      you say the name to me” <br />
      <strong> 3. Introduce letter sounds </strong> <br />
      “Each letter has a sound. The letter X says /X/. What sound?” <br />
      <strong> 4. Letter sound practice </strong> <br />
      “Now I’ll point to a letter and you say the sound” <br />
    </p>,
  ],
  StudentDecodingByAnalogyCard: [
    <p className="mb-6" key="paragraph-1">
      <strong> 1. Introduce letter names </strong> <br />
      “Let’s work with letters, we’ll learn # new letters today! This is capital X and lowercase X
      <br />
      What letter?” <br />
      <strong> 2. Letter name practice </strong> <br />
      “I’ll name a letter. You point to it and say it back to me” “Now I’ll point to a letter and
      you say the name to me” <br />
      <strong> 3. Introduce letter sounds </strong> <br />
      “Each letter has a sound. The letter X says /X/. What sound?” <br />
      <strong> 4. Letter sound practice </strong> <br />
      “Now I’ll point to a letter and you say the sound” <br />
    </p>,
  ],
  StudentWordDecodingCard: [
    <p className="mb-6" key="paragraph-1">
      <strong> 1. Introduce letter names </strong> <br />
      “Let’s work with letters, we’ll learn # new letters today! This is capital X and lowercase X
      <br />
      What letter?” <br />
      <strong> 2. Letter name practice </strong> <br />
      “I’ll name a letter. You point to it and say it back to me” “Now I’ll point to a letter and
      you say the name to me” <br />
      <strong> 3. Introduce letter sounds </strong> <br />
      “Each letter has a sound. The letter X says /X/. What sound?” <br />
      <strong> 4. Letter sound practice </strong> <br />
      “Now I’ll point to a letter and you say the sound” <br />
    </p>,
  ],
  StudentTrackingDecodableTextCard: [
    <p className="mb-6" key="paragraph-1">
      <strong> 1. Introduce letter names </strong> <br />
      “Let’s work with letters, we’ll learn # new letters today! This is capital X and lowercase X
      <br />
      What letter?” <br />
      <strong> 2. Letter name practice </strong> <br />
      “I’ll name a letter. You point to it and say it back to me” “Now I’ll point to a letter and
      you say the name to me” <br />
      <strong> 3. Introduce letter sounds </strong> <br />
      “Each letter has a sound. The letter X says /X/. What sound?” <br />
      <strong> 4. Letter sound practice </strong> <br />
      “Now I’ll point to a letter and you say the sound” <br />
    </p>,
  ],
  StudentSpellingCard: [
    <p className="mb-6" key="paragraph-1">
      <strong> 1. Introduce letter names </strong> <br />
      “Let’s work with letters, we’ll learn # new letters today! This is capital X and lowercase X
      <br />
      What letter?” <br />
      <strong> 2. Letter name practice </strong> <br />
      “I’ll name a letter. You point to it and say it back to me” “Now I’ll point to a letter and
      you say the name to me” <br />
      <strong> 3. Introduce letter sounds </strong> <br />
      “Each letter has a sound. The letter X says /X/. What sound?” <br />
      <strong> 4. Letter sound practice </strong> <br />
      “Now I’ll point to a letter and you say the sound” <br />
    </p>,
  ],
};

const convertDuration = (duration) => {
  if (duration < 60) {
    return `${duration} second${duration !== 1 ? "s" : ""}`;
  }
  const minutes = Math.floor(duration / 60);
  const remainingSeconds = duration % 60;
  return `${minutes} minute${minutes !== 1 ? "s" : ""}${remainingSeconds ? ` ${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}` : ""}`;
};

const title = (type, duration) => {
  return typeToTitleMap[type] + (duration ? ` (${convertDuration(duration)})` : "");
};

const StudentPlaceholderLessonCard = (props) => (
  <StudentAssessment
    {...props}
    data-testid={typeToIdMap[props.type]}
    queryKey={[typeToIdMap[props.type], props.assessmentId]}
    resetModalContent={`All the ${typeToTitleMap[props.type]} assessment data you marked will be erased and reset for this lesson. Are you sure you want to reset?`}
    resetModalTitle={`Reset ${typeToTitleMap[props.type]} Assessment`}
  >
    <StudentAssessmentHeader
      assessmentName={title(props.type, props.duration)}
      icon={<BookOpenSolid />}
      tooltipContent={typeToTooltipContentMap[props.type]}
    />
    <StudentAssessmentContentExpanded>
      <div className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0">
        <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 border-zinc-200 sticky">
          <div className="xl:px-5 pt-6 xl:pb-8 flex-col">
            <CompleteAssessmentForm />
          </div>
        </div>
        <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[36rem] relative">
          <div className="flex flex-col">
            <div>{typeToDescriptionMap[props.type]}</div>
          </div>
        </div>
      </div>
    </StudentAssessmentContentExpanded>
    <StudentAssessmentContentCollapsed></StudentAssessmentContentCollapsed>
  </StudentAssessment>
);
export default StudentPlaceholderLessonCard;
